
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-database mr-2"></i>
        <span class="font-weight-bold">Account Details</span>
      </p>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="p-3">
        <div class="form-row">
          <div class="col-7">
            <div class="form-group">
              <label>Custodian</label>
              <input v-model="account.custodian" class="form-control form-control-sm" placeholder="Master"
                :readonly="account.readonly(`master`)" />
              <small class="form-text text-muted">Account Custodian</small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Number</label>
              <input v-model="account.number" class="form-control form-control-sm" placeholder="Number"
                :readonly="account.readonly(`number`)" />
              <small class="form-text text-muted">Account Number</small>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label>Name</label>
              <input v-model="account.name" class="form-control form-control-sm" placeholder="Name" />
              <small class="form-text text-muted">Account Name</small>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label>Household</label>
              <select v-model="account.household" class="form-control form-control-sm"
                :disabled="account.disabled(`household`)">
                <option v-for="h in account.households" :key="h._id">
                  <span v-text="h.name"></span>
                </option>
              </select>
              <small class="form-text text-muted">Account Household</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <remove-button v-if="!account.isListedUnderCustodian" :button="account.remove"></remove-button>
        <button type="button" class="btn btn-sm btn-primary ml-2" :disabled="account.disabled(`save`)"
          v-on:click="account.click(`save`)">
          <span class="button">Save</span>
        </button>
      </div>
    </div>
  </div>
</div>
