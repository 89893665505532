<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-house-user mr-2"></i>
          <span class="font-weight-bold" v-text="household.text(`name`)"></span>
        </p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <scroll-area class="px-4 py-3">
          <div class="form-row">
            <div class="col-3">
              <div class="form-group">
                <label>Id</label>
                <input
                  v-model="household.id"
                  class="form-control form-control-sm"
                  placeholder="Auto"
                  disabled
                />
                <small class="form-text text-muted">Household Id</small>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Name<span class="red-asterisk">*</span></label>
                <input
                  v-model="household.name"
                  class="form-control form-control-sm"
                  placeholder="Name"
                />
                <small class="form-text text-muted">Household Name</small>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <table class="table table-sm table-borderless">
                <thead>
                  <tr>
                    <td>
                      <span>First Name<span class="red-asterisk">*</span></span>
                    </td>
                    <td>
                      <span>Last Name<span class="red-asterisk">*</span></span>
                    </td>
                    <td>
                      <span>Email</span>
                    </td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(o, i) in household.contacts" :key="i" v-if="!o.shouldDelete">
                    <td>
                      <input
                        v-model="o.firstName"
                        class="form-control form-control-sm"
                        placeholder="Name"
                      />
                    </td>
                    <td>
                      <input
                        v-model="o.lastName"
                        class="form-control form-control-sm"
                        placeholder="Last Name"
                      />
                    </td>
                    <td>
                      <input
                        v-model="o.email"
                        type="email"
                        class="form-control form-control-sm"
                        placeholder="Email"
                      />
                    </td>
                    <td class="align-middle" style="min-width: 50px;">
                      <i
                        class="fas fa-plus-circle mr-2"
                        :class="household.class(`add`, i)"
                        :title="household.title(`add`, i)"
                        v-on:click="household.click(`add`, i)"
                      ></i>
                      <i
                        class="fas fa-minus-circle"
                        :class="household.class(`remove`, i)"
                        :title="household.title(`remove`, i)"
                        v-on:click="household.click(`remove`, i)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr />
          <label>Advisor</label>
          <div class="form-row">
            <div class="col vertical-line">
              <div class="dropdown" ref="dropdown">
                <button 
                class="btn btn-sm btn-outline-secondary dropdown-toggle" 
                type="button" 
                  id="ddm-btn-advisors" 
                  data-toggle="dropdown" 
                  aria-haspopup="true" 
                  aria-expanded="false">
                  {{ selectedAdvisor ? selectedAdvisor.name : 'Select Advisor' }}
                </button>
                <div class="dropdown-menu"  
                aria-labelledby="ddm-btn-advisors">
                  <a 
                    v-for="advisor in advisors" 
                    :key="advisor.id" 
                    class="dropdown-item" 
                    @click="selectAdvisor(advisor)" href="#" >
                    {{ advisor.name }}
                    &nbsp;
                    <button           
                      v-if="advisor.id !== -1"
                      @click.stop="confirmDelete(advisor)"
                      type="button" 
                      class="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </a>
                </div>
                <small class="form-text text-muted">Choose an advisor for this household</small>
              </div>
            </div>
            <div class="col">
              <div class="input-group input-group-sm">
                <input type="text" class="form-control" 
                  placeholder="Advisor's name" 
                  aria-label="Advisor's name" 
                  ref="advisorNameInput"
                  @keypress.enter="addAdvisor">
                <div class="input-group-append">
                  <button 
                  @click="addAdvisor"
                  class="btn btn-outline-secondary" 
                  type="button">Add</button>
                </div>
              </div>
              <small class="form-text text-muted">Add a new advisor</small>
            </div>
            </div>
            <hr />
          <label>Household Address</label>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                    <input type="text" class="form-control form-control-sm" 
                    ref="addressLine1" :value="household.address.addressLine1" required>
                    <small class="form-text text-muted">Address Line 1</small>
              </div>
              <div class="form-group">
                    <input type="text" class="form-control form-control-sm" 
                    :value="household.address.addressLine2"
                    ref="addressLine2">
                    <small class="form-text text-muted">Address Line 2 (optional)</small>
              </div>
              <div class="form-group">
                  <input type="text" class="form-control form-control-sm" 
                      :value="household.address.city" ref="city">
                  <small class="form-text text-muted">City</small> 
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <select class="form-control form-control-sm" ref="state">
                  <option value="">Select State/Province</option>
                  <optgroup label="United States">
                    <option v-for="(state, code) in usStates" 
                    :value="code" :key="code" :selected="household.address.state === code">{{ state }}</option>
                  </optgroup>
                  <optgroup label="Canadian Provinces">
                      <option v-for="(province, code) in canadianProvinces" 
                      :value="code" :key="code" :selected="household.address.state === code">{{ province }}</option>
                  </optgroup>
                </select> 
                <small class="form-text text-muted">State/Province</small>
              </div>
              <div class="form-group">
                  <input type="text" class="form-control form-control-sm" 
                  ref="postalcode" :value="household.address.postalcode" required>
                  <small class="form-text text-muted">Zip/Postal Code</small> 
              </div>
              <div class="form-group">
                <select class="form-control form-control-sm" ref="country" required>
                  <option value="">Select Country</option>
                  <option v-for="(country,code) in countries" 
                  :value="code" :key="code" :selected="household.address.country == code" >{{ country}}</option>
                </select>
                <small class="form-text text-muted">Country</small>
              </div>
            </div>
          </div>
        </scroll-area>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <remove-button :button="household.remove"></remove-button>
          <button
            type="button"
            class="btn btn-sm btn-primary ml-2"
            :disabled="household.disabled(`save`)"
            v-on:click="household.click(`save`)"
          >
            <span>Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
div.main {
  margin-right: -0.6em;
}
table {
  margin-left: -5px;
  margin-bottom: 2em;
}
i:not(.fa-house-user) {
  opacity: 0.3;
}
i:hover:not(.fa-house-user) {
  cursor: pointer;
  opacity: 1;
}
p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}
div.modal-body {
  height: calc(100vh - 12em);
}
.red-asterisk {
            color: rgb(216, 212, 212);
            margin-left: 2px;
            font-size: 12px;
            vertical-align: top;
        }
.vertical-line {
  border-right: 1px dotted rgb(211, 206, 206); 
  height: 100%; 
  margin-right: 8px;
  
}

#ddm-btn-advisors, .dropdown-item{
  text-transform:capitalize
}
.dropdown-menu {
    height: auto;
    max-height: 260px;
    overflow-x: hidden;
}
</style>

<script>
import { removeButton, scrollArea } from "../../../../component";
import { Api, Modal } from "../../../../factory";
import { alert } from "../../../../service";

export default {
  name: `ModalHousehold`,
  get components() {
    return {
      removeButton,
      scrollArea
    };
  },
  props: {
    data: {
      type: Object,
      default: () => ({ household: {} })
    }
  },
  data() {
    return {
      household: ``,
      advisors: null,
      selectedAdvisor: null,
      usStates: {
        "AL": "Alabama",
        "AK": "Alaska",
        "AZ": "Arizona",
        "AR": "Arkansas",
        "CA": "California",
        "CO": "Colorado",
        "CT": "Connecticut",
        "DE": "Delaware",
        "FL": "Florida",
        "GA": "Georgia",
        "HI": "Hawaii",
        "ID": "Idaho",
        "IL": "Illinois",
        "IN": "Indiana",
        "IA": "Iowa",
        "KS": "Kansas",
        "KY": "Kentucky",
        "LA": "Louisiana",
        "ME": "Maine",
        "MD": "Maryland",
        "MA": "Massachusetts",
        "MI": "Michigan",
        "MN": "Minnesota",
        "MS": "Mississippi",
        "MO": "Missouri",
        "MT": "Montana",
        "NE": "Nebraska",
        "NV": "Nevada",
        "NH": "New Hampshire",
        "NJ": "New Jersey",
        "NM": "New Mexico",
        "NY": "New York",
        "NC": "North Carolina",
        "ND": "North Dakota",
        "OH": "Ohio",
        "OK": "Oklahoma",
        "OR": "Oregon",
        "PA": "Pennsylvania",
        "RI": "Rhode Island",
        "SC": "South Carolina",
        "SD": "South Dakota",
        "TN": "Tennessee",
        "TX": "Texas",
        "UT": "Utah",
        "VT": "Vermont",
        "VA": "Virginia",
        "WA": "Washington",
        "WV": "West Virginia",
        "WI": "Wisconsin",
        "WY": "Wyoming"
      },
      canadianProvinces: {
        "AB": "Alberta",
        "BC": "British Columbia",
        "MB": "Manitoba",
        "NB": "New Brunswick",
        "NL": "Newfoundland and Labrador",
        "NS": "Nova Scotia",
        "NT": "Northwest Territories",
        "NU": "Nunavut",
        "ON": "Ontario",
        "PE": "Prince Edward Island",
        "QC": "Quebec",
        "SK": "Saskatchewan",
        "YT": "Yukon"
      },
      countries: {
        'US': "United States",
        'CA': "Canada"
      }
    };
  },
  async created() {
    this.init();
    const { data } = await Api.get(`advisors`)
    this.advisors = [{ id: null, name: "none" }, ...data];
    this.selectedAdvisor = this.advisors.length 
    ? this.advisors.find(a => a.id == this.data.household.firmAdvisorId) 
    : null;
  },
  methods: {
    async selectAdvisor(advisor) {
      if (!this.data.household._id) { 
        this.selectedAdvisor = advisor
        this.data.household.firmAdvisorId = advisor.id
        return // we do not have this household created yet!
      }
      try {
        var { data } = await Api.put("advisors/household",
          {
            householdId: this.data.household._id, 
            advisorId: advisor.id
          })
        if (data.affectedRows == 1) {
          this.selectedAdvisor = advisor 
          this.data.household.firmAdvisorId = advisor.id
          alert.message(`Successfully updated the advisor to (${advisor.name})`)
        } else {
          alert.error(`Unable to select the advisor!`)
        }
      } catch (e) {
        console.error(e);
        alert.error(e.message);
      }
    },
    async addAdvisor() {
      const advisorName = this.$refs.advisorNameInput.value
      if (!advisorName) return alert.error('Advisor name is required', 1500)
      try {
        var { data } = await Api.post("advisors", { name: advisorName.trim() });
        const newAdvisor = { name: data.name, id: data.id }
        this.advisors.push(newAdvisor)
        this.$refs.advisorNameInput.value = ''
        this.selectedAdvisor = newAdvisor
        this.data.household.firmAdvisorId = newAdvisor.id
        alert.message(`Added new advisor (${advisorName}) successfully`, 1500)
      } catch (e) {
        console.error(e);
        alert.error("Couldn't add the advisor at this time. Please try again later.");
      }
    },
    confirmDelete(advisor) {
      if (window.confirm(`Are you sure you want to delete "${advisor.name.trim()}"? This will remove "${advisor.name.trim()}" from every household it is currently linked to. This action is irreversible.`)) {
        this.deleteAdvisor(advisor);
      }
    },
    async deleteAdvisor(advisor) {
      try {
        const { data } = await Api.delete(`advisors/${advisor.id}`);
        
        if (data.affectedRows == 0)
          return alert.info("Couldn't not delete the advisor")

        const index = this.advisors.findIndex(a => a.id === advisor.id);
        if (index === -1)
          return alert.info("Unable to delete the advisor!")

        this.advisors.splice(index, 1);
        // remove the id from the household data passed to the modal
        // if it's the same advisor
        if (this.data.household.firmAdvisorId == advisor.id)
          this.data.household.firmAdvisorId = null

        // remove the selected advisor if it's the deleted one
        if (this.selectedAdvisor.id == advisor.id && this.advisors.length)
          this.selectedAdvisor = this.advisors[0]

        alert.message("Successfully deleted advisor from the firm", 2e3);

        // hide the dropdown menu
        const dropdownElement = this.$refs.dropdown;
        dropdownElement.querySelector('.dropdown-menu').classList.remove('show');

      } catch (e) {
        console.error(e);
        alert.error(e.message)
      }
    },
    init() {
      var { data } = this;
      var _this = this
      var household = {
        _add(i = -1) {
          if (i == this.contacts.length - 1)
            this.contacts.splice(i + 1, 0, { email: ``, lastName: ``, firstName: `` });
        },
        _invalid(type) {
          switch (type) {
            case `contact`:
              return this.contacts.find(o => !o.firstName.length || !o.lastName.length);
            default:
              throw new Error(`Invalid validation type, ${type}!`);
          }
        },
        _keys: [`_id`, `contacts`, `id`, `name`, `address`, `firmAdvisorId`],
        async _remove(i) {
          if (typeof i == `number`) {
            if (i > 0) {
              const adjustedContact = {shouldDelete: true, ...this.contacts[i]}
              this.contacts.splice(i, 1, adjustedContact);
            }
          } else {
            try {
              var { household: h, methods } = data;
              var r = await Api.delete(`households/${h._id}`);
              if (methods && typeof methods.success == `function`)
                return data.methods.success(r.data);
              Modal.close();
            } catch (e) {
              console.error(e);
              alert.error(e.message);
            }
          }
        },
        async _save() {
          var o = this._keys.reduce((o, key) => {
            switch (key) {
              case `_id`:
                break;
              case `contacts`:
                o[key] = this[key].filter(o => o.firstName);
                break;
              case `address`:
                const addressLine1 = _this.$refs.addressLine1.value;
                const addressLine2 = _this.$refs.addressLine2.value;
                const city = _this.$refs.city.value;
                const postalcode = _this.$refs.postalcode.value;
                const state = _this.$refs.state.value;
                const country = _this.$refs.country.value;
                const formData = { addressLine1, addressLine2, city, postalcode, state, country };
                o[key] = formData
                break;
              case `firmAdvisorId`:
                o[key] = _this.selectedAdvisor.id
                break;
              default:
                o[key] = this[key];
            }
            return o;
          }, {});
          try {
            // add addressId to save on quering the database
            if (data.household) o.addressId = data.household.addressId
            var { methods } = data;
            var r =
              data.household && data.household._id
                ? await Api.put(`households/${data.household._id}`, o)
                : await Api.post(`households`, o);
            if (methods && typeof methods.success == `function`)
              return data.methods.success(r.data);
            Modal.close();
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        change(type) {
          switch (type) {
            case `household`:
              break;
            default:
              throw new Error(`Invalid change type, ${type}!`);
          }
        },
        class(type, d) {
          switch (type) {
            case `add`:
              return d == this.contacts.length - 1 ? `` : `disabled`;
            case `remove`:
              return typeof d == `number`
                ? d > 0
                  ? ``
                  : `disabled`
                : this._delete
                  ? `btn-danger`
                  : `btn-outline-danger`;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        click(type, d) {
          switch (type) {
            case `add`:
              return this._add(d);
            case `save`:
              return this._save();
            case `remove`:
              return this._remove(d)
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        disabled(type) {
          switch (type) {
            case `remove`:
              return !data.household || !data.household._id;
            case `save`:
              return this.name.length < 1 || this._invalid(`contact`);
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        init() {
          var d = data && data.household ? data.household : {};
          this._keys.forEach(key => {
            switch (key) {
              case `contacts`:
                this[key] = d[key] ? d[key].slice() : [];
                break;
              default:
                this[key] = d[key] || ``;
            }
          });
          if (!this.contacts.length) this._add();
          this.remove = {
            click: () => {
              this._remove();
            },
            disabled: ``
          };
          return this;
        },
        remove: ``,
        text(type) {
          switch (type) {
            case `name`:
              return this.name ? `Household - ${this.name}` : `Household`;
            default:
              throw new Error(`Invalid text type, ${type}!`);
          }
        },
        title(type, d) {
          switch (type) {
            case `add`:
              return d == this.contacts.length - 1 ? `Add contact` : ``;
            case `remove`:
              return d > 0 ? `Remove contact` : ``;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        }
      };
      this.household = household.init();
    }
  }
};
</script>
