
<div class="modal-dialog modal-dialog-centered modal-xl"
     role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-file-alt mr-2"></i>
        <span class="font-weight-bold"
              v-text="invoice.text(`modal`)"></span>
      </p>
      <button type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <template v-if="invoice.ready">
        <scroll-area class="px-4 py-3">
          <p class="font-weight-bold">Invoice</p>
          <p v-if="invoice.quarterbill">
            <i>this firm is billing with quarterly bill function</i>
          </p>
          <p v-if="invoice.monthlyBill">
            <i>This firm is billing with monthly bill function</i>
          </p>
          <div class="form-row">
             <div class="col-3">
               <div class="form-group">
                 <date-picker :clearable="false" class="date-picker" format="MMM D, YYYY" v-model="invoice.date"></date-picker>
                 <small class="form-text text-muted">Invoice Date</small>
               </div>
             </div>
             <div class="col-3">
               <select v-model="invoice.type"
                       class="form-control form-control-sm text-capitalize"
                       :disabled="invoice.disabled(`type`)"
                       v-on:change="invoice.change(`type`)">
                 <option class="text-capitalize"
                         v-for="(o, i) in invoice.options(`type`)"
                         :key="i">
                   <span class="text-capitalize"
                         v-text="o"></span>
                 </option>
               </select>
               <small class="form-text text-muted">Invoice Type</small>
             </div>
          </div>
          <p class="font-weight-bold">Values used to calculate fees</p>
          <template v-if="invoice.isAdvance">
            <div class="form-row">
              <div class="form-group col-6" v-if="invoice.isLatest">
                <date-picker :clearable="false" :disabled-date="disableWeekends" 
                class="date-picker" format="MMM D, YYYY" 
                v-model="invoice.period.advance.latest"></date-picker>
                <small class="form-text text-muted">Latest</small>
              </div>
              <div class="form-group col-6" v-else>
                <date-picker :clearable="false" :disabled-date="disableWeekends" 
                class="date-picker" format="MMM D, YYYY" 
                v-model="invoice.period.advance.adb" range></date-picker>
                <small class="form-text text-muted">Average Daily Balance</small>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="form-row">
              <div class="form-group col-6" v-if="invoice.isLatest">
                <date-picker :clearable="false" :disabled-date="disableWeekends" 
                class="date-picker"  format="MMM D, YYYY" 
                :default-value="new Date()" 
                v-model="invoice.period.arrears.latest"></date-picker>
                <small class="form-text text-muted">Latest</small>
              </div>
              <div class="form-group col-6" v-else>
                <date-picker :clearable="false" :disabled-date="disableWeekends" 
                class="date-picker" format="MMM D, YYYY" 
                v-model="invoice.period.arrears.adb" range></date-picker>
                <small class="form-text text-muted">Average Daily Balance</small>
              </div>
            </div>
          </template>
          <div class="form-row">
              <div class="form-group col-6">
                <p class="font-weight-bold">Fee applied to the following period</p>
                <date-picker :clearable="false"  
                class="date-picker" format="MMM D, YYYY" 
                v-model="invoice.period.range" range></date-picker>
                <small class="form-text text-muted">Apply for Period</small>
              </div>
          </div>
          <hr />
          <div class="d-flex">
            <div>
              <p class="font-weight-bold">Accounts Due</p>
            </div>
            <div class="flex-grow-1 text-right">
              <span class="mr-2">Obfuscate account numbers</span>
              <checkbox :o="invoice.show"
                        p="numbers"
                        title="Toggle obfuscate account numbers"></checkbox>
            </div>
            <div class="text-right ml-4">
              <span class="mr-2">Show all accounts</span>
              <checkbox :o="invoice.show.all"
                        p="due"
                        title="Toggle all accounts"></checkbox>
            </div>
          </div>
          <table class="table table-sm accounts mb-5">
            <thead>
              <tr>
                <th>
                  <span>Number</span>
                </th>
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Household</span>
                </th>
                <th class="text-right">
                  <span>Billing</span>
                </th>
                <th class="text-right">
                  <span>Amount</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(o, i) in invoice.accounts(`due`)"
                  :key="i">
                <td>
                  <span v-text="o.number"></span>
                </td>
                <td>
                  <span v-text="o.name"></span>
                </td>
                <td>
                  <span v-text="o.household"></span>
                </td>
                <td class="text-right">
                  <span v-text="o.billing"></span>
                </td>
                <td class="text-right">
                  <span v-text="invoice.text(`due`, o)"></span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3"></td>
                <td class="text-right">
                  <span class="font-weight-bold">Total</span>
                </td>
                <td class="text-right">
                  <span class="total"
                        v-text="invoice.text(`total`, `due`)"></span>
                </td>
              </tr>
            </tfoot>
          </table>
          <hr />
          <div class="d-flex">
            <div>
              <p class="font-weight-bold">Account Fees</p>
            </div>
            <div class="flex-grow-1 text-right">
              <span class="mr-2">Show all fees</span>
              <checkbox :o="invoice.show.all"
                        p="fees"
                        title="Toggle all fees"></checkbox>
            </div>
          </div>
          <table class="table table-sm accounts mb-0">
            <thead>
              <tr>
                <th>
                  <span>Number</span>
                </th>
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Schedule</span>
                </th>
                <th class="text-right">
                  <span>Value</span>
                </th>
                <th class="text-right">
                  <span>Fee</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(o, i) in invoice.accounts(`fees`)"
                  :key="i">
                <td>
                  <span v-text="o.number"></span>
                </td>
                <td>
                  <span v-text="o.name"></span>
                </td>
                <td>
                  <span v-text="o.schedule"></span>
                </td>
                <td class="text-right">
                  <span v-text="invoice.text(`value`, o)"></span>
                </td>
                <td class="text-right">
                  <span v-text="invoice.text(`fee`, o)"></span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2"></td>
                <td class="text-right">
                  <span class="font-weight-bold">Total</span>
                </td>
                <td class="text-right">
                  <span class="total"
                        v-text="invoice.text(`total`, `account-values`)"></span>
                </td>
                <td class="text-right">
                  <span class="total"
                        v-text="invoice.text(`total`, `account-fees`)"></span>
                </td>
              </tr>
            </tfoot>
          </table>
          <p class="text-right pr-3">
            <small class="text-muted"
                   v-text="invoice.text(`owing`)"></small>
          </p>
          <hr />
          <div style="padding-bottom: 0.5rem">
            <div class="d-flex">
              <span class="font-weight-bold">Schedules</span>
              <div class="flex-grow-1 text-right">
                <span class="mr-2">Show all tiers</span>
                <checkbox :o="invoice.show.all"
                          p="tiers"
                          title="Toggle all tiers"></checkbox>
              </div>
            </div>

            <span class="text-secondary"
                  v-if="invoice.group">
              <small>
                These schedules are applied in a group. Exceptions are 
                shared among members of a group.
              </small>
            </span>
          </div>
          <div v-for="(o, index) in invoice.schedules"
               :key="index">
            <hr class="mt-0" />
            <p class="schedule"
               v-text="invoice.text(`schedule`, o, index)"></p>
            <table class="table table-sm schedule mb-4">
              <thead>
                <tr>
                  <th>
                    <span>Tier</span>
                  </th>
                  <th class="text-right">
                    <span>Rate</span>
                  </th>
                  <th v-if="invoice.group"
                      class="text-right">
                    <span>Applied group rate</span>
                  </th>
                  <th></th>
                  <th class="text-right">
                    <span>Value</span>
                  </th>
                  <th class="text-right">
                    <span>Fee</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(split, i) of invoice.tiers(o)"
                    :key="i">
                  <td>
                    <span v-text="invoice.text(`tier`, o, i)"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`rate`, o, i)"></span>
                  </td>
                  <td v-if="invoice.group"
                      class="text-right">
                    <span v-text="invoice.text(`group-rate`, o, i)"></span>
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span v-text="invoice.text(`split`, o, i)"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`fee`, o, i)"></span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2"></td>

                  <td v-if="invoice.group"></td>

                  <td class="text-right">
                    <span class="font-weight-bold">Total</span>
                  </td>
                  <td class="text-right">
                    <span class="total"
                          v-text="invoice.text(`total`, `schedule-splits`, o)"></span>
                  </td>
                  <td class="text-right">
                    <span class="total"
                          v-text="invoice.text(`total`, `schedule-fees`, o)"></span>
                  </td>
                </tr>
              </tfoot>
            </table>
            <template v-if="o.owing">
              <p class="text-right">
                <small class="text-muted"
                       v-text="invoice.text(`owing`, o)"></small>
              </p>
            </template>
          </div>

          <p class="text-right pr-3">
            <small class="text-muted"></small>
          </p>
          <hr />

          <div v-if="invoice.lineItems.length"
               class="mb-4">
            <p class="font-weight-bold">Line Items</p>
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>
                    <span>Description</span>
                  </th>
                  <th>
                    <span>Line Item Period</span>
                  </th>
                  <th>
                    <span>Account</span>
                  </th>
                  <th>
                    <span>Timing</span>
                  </th>
                  <th>
                    <span>Amount</span>
                  </th>
                  <th class="text-center">
                    <span>Due</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template>
                  <tr  v-for="(o, i) in invoice.lineItems" 
                  :key="i" v-show="invoice.showLineItem(o)">
                    <td>
                      <span v-text="o.description"></span>
                    </td>
                    <td>
                      <span>{{ invoice.text(`lineitem-period`, o) }}</span>
                    </td>
                    <td>
                      <span v-text="invoice.text(`account`, o)"></span>
                    </td>
                    <td>
                      <span v-text="o.timing"></span>
                    </td>
                    <td>
                      <span v-text="invoice.text(`amount`, o)"></span>
                    </td>
                    <td  class="text-center">
                      <span v-if="o.timing.toLowerCase() == `annual`">
                      {{ invoice.text(`amount-due`, o) }}</span>
                      <span v-else>N/A</span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <p class="text-right pr-3"
             v-if="invoice.lineItems.length">
            <small class="text-muted"></small>
          </p>
          <hr v-if="invoice.lineItems.length" />

          <!-- <p class="font-weight-bold">Line Items</p>
          <table class="table table-sm line-items mb-4">
            <thead>
              <tr>
                <th>
                  <span>Account</span>
                </th>
                <th class="text-right">
                  <span class="mr-1">Value</span>
                </th>
                <th>
                  <span class="ml-1">Comment</span>
                </th>
                <th class="text-right">
                  <i
                    class="fas fa-plus-circle"
                    :title="invoice.title(`add`, `line-item`)"
                    v-on:click="invoice.click(`add`, `line-item`)"
                  ></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in invoice.line.items" :key="idx">
                <td>
                  <select
                    v-model="item.account"
                    class="form-control form-control-sm"
                  >
                    <option
                      v-for="(o, i) in invoice.accounts(`line-items`)"
                      :key="i"
                    >
                      <span v-text="o"></span>
                    </option>
                  </select>
                </td>
                <td class="text-right">
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <b class="px-1">$</b>
                      </span>
                    </div>
                    <input
                      v-model="item.value"
                      class="form-control form-control-sm text-right"
                      placeholder="0"
                      v-on:focus="invoice.focus(`line-item`, item, `value`)"
                      v-on:blur="invoice.blur(`line-item`, item, `value`)"
                    />
                  </div>
                </td>
                <td>
                  <input
                    v-model="item.comment"
                    class="form-control form-control-sm"
                    placeholder="Comment"
                  />
                </td>
                <td class="text-right">
                  <i
                    class="fas fa-minus-circle"
                    :title="invoice.title(`remove`, `line-item`, idx)"
                    v-on:click="invoice.click(`remove`, `line-item`)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>-->

          <template v-if="invoice.lastInvoice">
            <div>
              <div class="d-flex">
                <div>
                  <p class="font-weight-bold">Adjustments</p>
                </div>
              </div>

              <hr class="mt-0" />
              <p class="schedule">
                {{ invoice.display_date(invoice.lastInvoice.period.start) }} -
                {{ invoice.display_date(invoice.lastInvoice.period.end) }}
              </p>
            </div>
          </template>
          <template v-else>
            <div></div>
          </template>
          <template v-if="invoice.accountsChanges">
            <div>
              <hr class="mt-0" />
              <p class="schedule">Accounts Adjustments</p>
              <table class="table table-sm accounts mb-4">
                <thead>
                  <tr>
                    <th>
                      <span>Number</span>
                    </th>
                    <th>
                      <span>Name</span>
                    </th>
                    <th>
                      <span>Schedule</span>
                    </th>
                    <th class="text-right">
                      <span>Change</span>
                    </th>
                    <th class="text-right">
                      <span>Adjustment</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ac, i) of invoice.accountsChanges"
                      :key="i">
                    <td>
                      <span v-text="ac.number"></span>
                    </td>
                    <td>
                      <span v-text="invoice.text(`account-name`, ac)"></span>
                    </td>
                    <td>
                      <span v-text="invoice.text(`schedule-name`, ac)"></span>
                    </td>
                    <td class="text-right no-wrap">
                      <span v-text="ac.change_f"></span>
                    </td>
                    <td class="text-right no-wrap">
                      <span v-text="ac.adjustment_f"></span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2"></td>
                    <td class="text-right">
                      <span class="font-weight-bold">Total</span>
                    </td>
                    <td class="text-right no-wrap">
                      <span class="total"
                            v-text="
                              invoice.text(`total`, `accounts-changes-change`)
                            "></span>
                    </td>
                    <td class="text-right no-wrap">
                      <span class="total"
                            v-text="
                              invoice.text(`total`, `accounts-changes-adjustment`)
                            "></span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </template>
          <template v-else>
            <div></div>
          </template>

          <template v-if="invoice.schedulesChanges">
            <div>
              <hr class="mt-0" />
              <p class="schedule">Schedules changes</p>
              <table class="table table-sm mb-4">
                <thead>
                  <tr>
                    <th>
                      <span>Schedule</span>
                    </th>
                    <th class="text-right"
                        style="width: 1px">
                      <span>Change</span>
                    </th>
                    <th class="text-right">
                      <span>Adjustment</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(sc, i) of invoice.schedulesChanges"
                      :key="i">
                    <td>
                      <span v-text="sc.name"></span>
                    </td>
                    <td class="text-right no-wrap">
                      <span v-text="sc.change_f"></span>
                    </td>
                    <td class="text-right no-wrap">
                      <span v-text="sc.adjustment_f"></span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="text-right">
                      <span class="font-weight-bold">Total</span>
                    </td>
                    <td class="text-right no-wrap">
                      <span class="total"
                            v-text="
                              invoice.text(`total`, `schedules-changes-change`)
                            "></span>
                    </td>
                    <td class="text-right no-wrap">
                      <span class="total"
                            v-text="
                              invoice.text(
                                `total`,
                                `schedules-changes-adjustment`
                              )
                            "></span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </template>
          <template v-else>
            <div></div>
          </template>

          <div v-if="invoice.hasTransactions">
            <rebated-transactions :transactions="invoice.transactions"
                                  :obfuscateNumbers="invoice.show.numbers"></rebated-transactions>
          </div>
          <div v-else></div>


          <div class="d-flex">
            <div>
              <p class="font-weight-bold">Grand total</p>
            </div>
          </div>
          <table class="table table-sm mb-4">
            <thead>
              <tr>
                <th style="width: 8%; white-space: nowrap">
                  <span>Account</span>
                </th>
                <th>
                  <span>Name</span>
                </th>
                <th></th>
                <th class="text-right">
                  <span>Amount</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(ac, i) of invoice.accounts(`grandtotal`)"
                  :key="i">
                <td>
                  <span v-text="ac.number"></span>
                </td>
                <td>
                  <span v-text="ac.name"></span>
                </td>
                <td :set="nTotal = invoice.getAccountGrandTotal(ac)">
                  <span v-if="nTotal < 0">
                    <em>(Carryover {{value(nTotal * -1) }})</em>
                  </span>
                </td>
                <td class="text-right">
                  <span v-text="invoice.text(`account-grand-total`, ac)"></span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td class="text-right">
                 <span class="font-weight-bold">Total</span>
                </td>
                <td class="text-right no-wrap">
                  <span class="total"
                        v-text="invoice.text(`total`, `account-grand-total`)"></span>
                </td>
              </tr>
            </tfoot>
          </table>
        </scroll-area>
      </template>
      <template v-else>
        <loading></loading>
      </template>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <button type="button"
                class="btn btn-sm btn-primary"
                :disabled="invoice.disabled(`submit`)"
                v-on:click="invoice.click(`submit`)">Submit</button>
      </div>
    </div>
  </div>
</div>
