
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title">New Client Onboarding</div>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <div class="px-4 py-3">
        <div class="form-row">
          <div class="col-12">
            <div class="form-group">
              <OnboardingLabel :label="household.name.label" :required="household.name.required" />
              <vue-autosuggest
                v-model="household.name.value"
                  :suggestions="filteredOptions"
                  :limit="5"
                  @input="onInputChange"
                  @selected="onSelected"
                  :input-props="inputProps" >
              </vue-autosuggest>
              <div class="form-check">
               <input class="form-check-input" 
               type="checkbox" :disabled="disableCreateInCrm" 
               v-model="createInCrm" id="crmCheckbox">
               <label class="form-check-label small" for="crmCheckbox">
                 Create household in the CRM 
               </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-2">
          <div class="col-3">
            <OnboardingLabel :label="contacts.firstName.label" :required="contacts.firstName.required"  />
          </div>

          <div class="col-3">
            <OnboardingLabel :label="contacts.lastName.label" :required="contacts.lastName.required" />
          </div>

          <div class="col-3">
            <OnboardingLabel :label="contacts.email.label" :required="contacts.email.required"  />
          </div>
        </div>

        <ContactRow :contact="contacts" v-on:plus="addContact()" />

        <div v-for="(contact, i) in contacts.array">
          <ContactRow
            class="mt-2"
            :key="contact.id"
            :contact="contact"
            :i="i"
            v-on:plus="addContact()"
            v-on:minus="removeContact(i)"
          />
        </div>

        <div class="form-row">
          <div class="col-12">
            <OnboardingSmall :small="contacts.small" />
          </div>
        </div>

        <hr />
        <label>Advisor</label>
        <div class="form-row">
          <div class="col vertical-line">
            <div class="dropdown" ref="dropdown">
              <button 
              class="btn btn-sm btn-outline-secondary dropdown-toggle" 
              type="button" 
                id="ddm-btn-advisors" 
                data-toggle="dropdown" 
                aria-haspopup="true" 
                aria-expanded="false">
                {{ selectedAdvisor ? selectedAdvisor.name : 'Select Advisor' }}
              </button>
              <div class="dropdown-menu"  
              aria-labelledby="ddm-btn-advisors">
                <a 
                  v-for="advisor in advisors" 
                  :key="advisor.id" 
                  class="dropdown-item" 
                  @click="selectedAdvisor = advisor" href="#" >
                  {{ advisor.name }}
                </a>
              </div>
              <small class="form-text text-muted">Choose an advisor for this household</small>
            </div>
          </div>
          <div class="col">
            <div class="input-group input-group-sm">
              <input type="text" class="form-control" 
                placeholder="Advisor's name" 
                aria-label="Advisor's name" 
                ref="advisorNameInput"
                @keypress.enter="addAdvisor">
              <div class="input-group-append">
                <button 
                @click="addAdvisor"
                class="btn btn-outline-secondary" 
                type="button">Add</button>
              </div>
            </div>
            <small class="form-text text-muted">Add a new advisor</small>
          </div>
        </div>
        <hr />
        <label id="addressLabel" data-toggle="collapse" data-target="#collapsibleSection">Household Address
          <span class="chevron"><i class="fas fa-chevron-down"></i></span>
        </label>
        <div id="collapsibleSection" class="form-row collapse">
          <div class="col">
            <div class="form-group">
                  <input type="text" class="form-control form-control-sm" 
                  v-model="address.addressLine1" required>
                  <small class="form-text text-muted">Address Line 1</small>
            </div>
            <div class="form-group">
                  <input type="text" class="form-control form-control-sm" 
                  v-model="address.addressLine2">
                  <small class="form-text text-muted">Address Line 2 (optional)</small>
            </div>
            <div class="form-group">
                <input type="text" class="form-control form-control-sm" 
                v-model="address.city">
                <small class="form-text text-muted">City</small> 
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <select class="form-control form-control-sm" v-model="address.state">
                <option value="">Select State/Province</option>
                <optgroup label="United States">
                  <option v-for="(state, code) in usStates" 
                  :value="code" :key="code">{{ state }}</option>
                </optgroup>
                <optgroup label="Canadian Provinces">
                    <option v-for="(province, code) in canadianProvinces" 
                    :value="code" :key="code">{{ province }}</option>
                </optgroup>
              </select> 
              <small class="form-text text-muted">State/Province</small>
            </div>
            <div class="form-group">
                <input type="text" class="form-control form-control-sm" 
                v-model="address.postalcode" required>
                <small class="form-text text-muted">Zip/Postal Code</small> 
            </div>
            <div class="form-group">
              <select class="form-control form-control-sm" v-model="address.country" required>
                <option value="" disabled>Select Country</option>
                <option v-for="(country,code) in countries" 
                :value="code" :key="code" >{{ country }}</option>
              </select>
              <small class="form-text text-muted">Country</small>
            </div>
          </div>
        </div>
        <hr />
        <div class="form-row mt-3">
          <div class="col-12">
            <OnboardingTitle :title="disclosureNotices.title" />
            <OnboardingSmall :small="disclosureNotices.small" />
          </div>

          <div
            class="col-6 mt-2" :key="i"
            v-for="(option, i) in disclosureNotices.options" >
            <OnboardingCheckbox class="mr-2 file-checkbox" v-model="option.checked" />
            <OnboardingLabel class="file-label" :label="option.label" />
          </div>
        </div>

        <hr>
        <div class="form-row mt-3">
          <div class="col-12">
            <OnboardingTitle :title="clientAgreement.title" />
          </div>
          <div class="col-7">
            <OnboardingLabel :label="clientAgreement.radio.label" />
          </div>

          <div class="col">
            <OnboardingRadio
              class="mr-2"
              :radioName="clientAgreement.title"
              :radioValue="clientAgreement.radio.options.yes"
              v-model="clientAgreement.radio.value"
            />
            <OnboardingLabel :label="clientAgreement.radio.options.yes" />
          </div>

          <div class="col">
            <OnboardingRadio
              class="mr-2"
              :radioName="clientAgreement.title"
              :radioValue="clientAgreement.radio.options.no"
              v-model="clientAgreement.radio.value"
            />
            <OnboardingLabel :label="clientAgreement.radio.options.no" />
          </div>
          <hr>
          <div
            class="form-row mt-2"
            v-if="clientAgreement.radio.value == clientAgreement.radio.options.no">
            <div class="col-2"></div>
            <div class="col-8">
              <OnboardingLabel
                class="text-center"
                label="You will be able to send a contract for signature at a later time at your discretion"
              />
            </div>
          </div>
          </div>
          <div class="form-row mt-2" 
              v-if="clientAgreement.radio.value == clientAgreement.radio.options.yes" >
                <div class="col-4">
                   <OnboardingLabel :label="clientAgreement.template.label" />
                   <select class="form-control form-control-sm" v-model="clientAgreement.template.value" required>
                     <option value="" disabled>Select Template</option>
                       <option v-for="template in templates" 
                       :value="template.id" :key="template.id">{{ template.name }}</option>
                     </select>
                   <OnboardingSmall :small="clientAgreement.template.small" />
                </div>
                <div class="col-4">
                   <OnboardingLabel :label="clientAgreement.schedule.label" />
                   <select class="form-control form-control-sm" v-model="clientAgreement.schedule.value" required>
                     <option value="" disabled>Select Schedule</option>
                       <option v-for="schedule in schedules" 
                       :value="schedule._id" :key="schedule._id">{{ schedule.name }}</option>
                     </select>
                   <OnboardingSmall :small="clientAgreement.schedule.small" />
                </div>
                <div class="col-4">
                  <OnboardingLabel :label="clientAgreement.note.label" />
                  <OnboardingInput v-model="clientAgreement.note.value" />
                  <OnboardingSmall :small="clientAgreement.note.small" />
                </div>
          </div>
        </div>
    </div>

    <div class="modal-footer d-flex justify-content-between">
      <div class="text-left">
          <p class="text-muted">
            Remaining Signature Requests: {{ _firmSignRequestsLimit ? _firmSignRequestsLimit : 0 }}<br>
            Need more? contact sales@smartkx.io
          </p>
        </div>
      <div class="text-right">
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          class="btn btn-sm modal-button btn-outline-danger ml-2"
        >
          <span>Cancel</span>
        </button>
        <button
          type="button"
          class="btn btn-sm modal-button btn-primary ml-2"
          v-on:click="submit()"
          :disabled="disableSubmit"
        >
          <span>Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>
