
<div class="modal-dialog modal-dialog-centered modal-xl"
     role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-file-alt mr-2"></i>
        <span class="font-weight-bold"
              v-text="invoice.text(`modal`)"></span>
      </p>
      <button type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <template v-if="invoice.ready">
        <scroll-area class="px-4 py-3">
          <div class="form-row">
            <div class="col-12">
              <p class="font-weight-bold">Invoice</p>
              <div class="form-row">
                <div class="col-3">
                  <div class="form-group">
                    <date-picker :o="invoice"
                                 p="date"
                                 :options="invoice.options(`date`)"></date-picker>
                    <small class="form-text text-muted">Invoice Date</small>
                  </div>
                </div>
                <div class="col-3">
                  <select v-model="invoice.type"
                          class="form-control form-control-sm text-capitalize"
                          disabled>
                    <option class="text-capitalize"
                            v-for="(o, i) in invoice.options(`type`)"
                            :key="i">
                      <span class="text-capitalize"
                            v-text="o"></span>
                    </option>
                  </select>
                  <small class="form-text text-muted">Invoice Type</small>
                </div>
              </div>
            </div>
            <div class="col">
              <p class="font-weight-bold">Period</p>
              <div class="form-row">
                <div class="col-3">
                    <select v-model="invoice.values"
                            class="form-control form-control-sm"
                            disabled>
                      <option v-for="(o, i) in invoice.options(`period`, `values`)"
                              :key="i">
                        <span v-text="o"></span>
                      </option>
                    </select>
                  <small class="form-text text-muted">Period Values</small>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <date-picker :o="invoice.period"
                                 p="start"
                                 :options="invoice.options(`period`, `start`)"></date-picker>
                    <small class="form-text text-muted">Period Start</small>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <date-picker :o="invoice.period"
                                 p="end"
                                 :options="invoice.options(`period`, `end`)"></date-picker>
                    <small class="form-text text-muted">Period End</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="d-flex">
            <div>
              <p class="font-weight-bold"
                 v-text="invoice.text(`accounts`, `due`)"></p>
            </div>
            <div class="flex-grow-1 text-right">
              <span class="mr-2">Obfuscate account numbers</span>
              <checkbox :o="invoice.show"
                        p="numbers"
                        title="Toggle obfuscate account numbers"></checkbox>
            </div>
            <div class="text-right ml-4">
              <span class="mr-2">Show all accounts</span>
              <checkbox :o="invoice.show.all"
                        p="due"
                        title="Toggle all accounts"></checkbox>
            </div>
          </div>
          <table class="table table-sm accounts mb-5">
            <thead>
              <tr>
                <th>
                  <span>Number</span>
                </th>
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Household</span>
                </th>
                <th class="text-right">
                  <span>Billing</span>
                </th>
                <th class="text-right">
                  <span>Amount</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(o, i) in invoice.accounts(`due`)"
                  :key="i">
                <td>
                  <span v-text="o.number"></span>
                </td>
                <td>
                  <span v-text="o.name"></span>
                </td>
                <td>
                  <span v-text="o.household"></span>
                </td>
                <td class="text-right">
                  <span v-text="o.billing"></span>
                </td>
                <td class="text-right">
                  <span v-text="invoice.text(`due`, o)"></span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3"></td>
                <td class="text-right">
                  <span class="font-weight-bold">Total</span>
                </td>
                <td class="text-right">
                  <span class="total"
                        v-text="invoice.text(`total`, `due`)"></span>
                </td>
              </tr>
            </tfoot>
          </table>
          <hr />
          <div class="d-flex">
            <div>
              <p class="font-weight-bold">Account Fees</p>
            </div>
            <div class="flex-grow-1 text-right">
              <span class="mr-2">Show all fees</span>
              <checkbox :o="invoice.show.all"
                        p="fees"
                        title="Toggle all fees"></checkbox>
            </div>
          </div>
          <table class="table table-sm accounts mb-0">
            <thead>
              <tr>
                <th>
                  <span>Number</span>
                </th>
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Schedule</span>
                </th>
                <th class="text-right">
                  <span>Value</span>
                </th>
                <th class="text-right">
                  <span>Fee</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(o, i) in invoice.accounts(`fees`)"
                  :key="i">
                <td>
                  <span v-text="o.number"></span>
                </td>
                <td>
                  <span v-text="o.name"></span>
                </td>
                <td>
                  <span v-text="o.schedule"></span>
                </td>
                <td class="text-right">
                  <span v-text="invoice.text(`value`, o)"></span>
                </td>
                <td class="text-right">
                  <span v-text="invoice.text(`fee`, o)"></span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2"></td>
                <td class="text-right">
                  <span class="font-weight-bold">Total</span>
                </td>
                <td class="text-right">
                  <span class="total"
                        v-text="invoice.text(`total`, `account-values`)"></span>
                </td>
                <td class="text-right">
                  <span class="total"
                        v-text="invoice.text(`total`, `account-fees`)"></span>
                </td>
              </tr>
            </tfoot>
          </table>
          <p class="text-right pr-3">
            <small class="text-muted"
                   v-text="invoice.text(`owing`)"></small>
          </p>
          <hr />
          <div style="padding-bottom: 0.5rem">
            <div class="d-flex">
              <span class="font-weight-bold">Schedules</span>
              <div class="flex-grow-1 text-right">
                <span class="mr-2">Show all tiers</span>
                <checkbox :o="invoice.show.all"
                          p="tiers"
                          title="Toggle all tiers"></checkbox>
              </div>
            </div>

            <span class="text-secondary"
                  v-if="invoice.group">
              <small>
                These schedules are applied in a group. Exceptions are 
                shared among members of a group.
              </small>
            </span>
          </div>
          <div v-for="(o, index) in invoice.schedules"
               :key="index">
            <hr class="mt-0" />
            <p class="schedule"
               v-text="invoice.text(`schedule`, o, index)"></p>
            <table class="table table-sm schedule mb-4">
              <thead>
                <tr>
                  <th>
                    <span>Tier</span>
                  </th>
                  <th class="text-right">
                    <span>Rate</span>
                  </th>
                  <th v-if="invoice.group"
                      class="text-right">
                    <span>Applied group rate</span>
                  </th>
                  <th></th>
                  <th class="text-right">
                    <span>Value</span>
                  </th>
                  <th class="text-right">
                    <span>Fee</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(split, i) of invoice.tiers(o)"
                    :key="i">
                  <td>
                    <span v-text="invoice.text(`tier`, o, i)"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`rate`, o, i)"></span>
                  </td>
                  <td v-if="invoice.group"
                      class="text-right">
                    <span v-text="invoice.text(`group-rate`, o, i)"></span>
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span v-text="invoice.text(`split`, o, i)"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`fee`, o, i)"></span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2"></td>

                  <td v-if="invoice.group"></td>
                  <td class="text-right">
                    <span class="font-weight-bold">Total</span>
                  </td>
                  <td class="text-right">
                    <span class="total"
                          v-text="invoice.text(`total`, `schedule-splits`, o)"></span>
                  </td>
                  <td class="text-right">
                    <span class="total"
                          v-text="invoice.text(`total`, `schedule-fees`, o)"></span>
                  </td>
                </tr>
              </tfoot>
            </table>
            <template v-if="o.owing">
              <p class="text-right">
                <small class="text-muted"
                       v-text="invoice.text(`owing`, o)"></small>
              </p>
            </template>
          </div>

          <div v-if="invoice.lineItems.length"
               class="mb-4">
            <p class="text-right pr-3">
              <small class="text-muted"></small>
            </p>
            <hr />
            <p class="font-weight-bold">Line Items</p>
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>
                    <span>Description</span>
                  </th>
                  <th>
                    <span>Account</span>
                  </th>
                  <th>
                    <span>Timing</span>
                  </th>

                  <th>
                    <span>Amount</span>
                  </th>
                  <th class="text-center">
                    <span>Due</span>
                    &nbsp;<i class="far fa-question-circle" 
                    title="Due amount only shows up for 'Annual' line items."></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(o, i) in invoice.lineItems"
                    :key="i">
                  <td>
                    <span v-text="o.description"></span>
                  </td>
                  <td>
                    <span v-text="invoice.text(`account`, o)"></span>
                  </td>
                  <td>
                    <span v-text="o.timing"></span>
                  </td>
                  <td>
                    <span v-text="invoice.text(`amount`, o)"></span>
                  </td>
                  <td  class="text-center">
                    <span v-if="o.timing.toLowerCase() == `annual`">
                    {{ invoice.text(`amount-due`, o) }}</span>
                    <span v-else>N/A</span>
                  </td>
                 </tr>
              </tbody>
            </table>
          </div>

          <template v-if="invoice.lastInvoice">
            <div>
              <div class="d-flex">
                <div>
                  <p class="font-weight-bold">Adjustments</p>
                </div>
              </div>

              <hr class="mt-0" />
              <p class="schedule">
                {{ invoice.display_date(invoice.lastInvoice.period.start) }} -
                {{ invoice.display_date(invoice.lastInvoice.period.end) }}
              </p>
            </div>
          </template>
          <template v-if="invoice.accountsChanges">
            <div>
              <hr class="mt-0" />
              <p class="schedule">Accounts Adjustments</p>
              <table class="table table-sm accounts mb-4">
                <thead>
                  <tr>
                    <th>
                      <span>Number</span>
                    </th>
                    <th>
                      <span>Name</span>
                    </th>
                    <th>
                      <span>Schedule</span>
                    </th>
                    <th class="text-right">
                      <span>Change</span>
                    </th>
                    <th class="text-right">
                      <span>Adjustment</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ac, i) of invoice.accountsChanges"
                      :key="i">
                    <td>
                      <span v-text="ac.number"></span>
                    </td>
                    <td>
                      <span v-text="invoice.text(`account-name`, ac)"></span>
                    </td>
                    <td>
                      <span v-text="invoice.text(`schedule-name`, ac)"></span>
                    </td>
                    <td class="text-right no-wrap">
                      <span v-text="ac.change_f"></span>
                    </td>
                    <td class="text-right no-wrap">
                      <span v-text="ac.adjustment_f"></span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2"></td>
                    <td class="text-right">
                      <span class="font-weight-bold">Total</span>
                    </td>
                    <td class="text-right no-wrap">
                      <span class="total"
                            v-text="
                              invoice.text(`total`, `accounts-changes-change`)
                            "></span>
                    </td>
                    <td class="text-right no-wrap">
                      <span class="total"
                            v-text="
                              invoice.text(`total`, `accounts-changes-adjustment`)
                            "></span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </template>
          <template v-else>
            <div></div>
          </template>

          <template v-if="invoice.schedulesChanges">
            <div>
              <hr class="mt-0" />
              <p class="schedule">Schedules changes</p>
              <table class="table table-sm mb-4">
                <thead>
                  <tr>
                    <th>
                      <span>Schedule</span>
                    </th>

                    <th class="text-right"></th>
                    <th style="width: 12%"
                        class="text-right">
                      <span>Change</span>
                    </th>
                    <th style="width: 12%"
                        class="text-right">
                      <span>Adjustment</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(sc, i) of invoice.schedulesChanges"
                      :key="i">
                    <td>
                      <span v-text="sc.name"></span>
                    </td>
                    <td></td>
                    <td class="text-right no-wrap">
                      <span v-text="sc.change_f"></span>
                    </td>
                    <td class="text-right no-wrap">
                      <span v-text="sc.adjustment_f"></span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td class="text-right no-wrap">
                      <span class="font-weight-bold">Total</span>
                    </td>
                    <td class="text-right no-wrap">
                      <span class="total"
                            v-text="
                              invoice.text(`total`, `schedules-changes-change`)
                            "></span>
                    </td>
                    <td class="text-right no-wrap">
                      <span class="total"
                            v-text="
                              invoice.text(
                                `total`,
                                `schedules-changes-adjustment`
                              )
                            "></span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </template>
          <template v-else>
            <div></div>
          </template>

          <!-- <template v-if="invoice.exceptionsChanges">
            <div>
              <hr class="mt-0" />
              <p class="schedule">Exceptions changes</p>
              <table class="table table-sm schedule-changes mb-4">
                <thead>
                  <tr>
                    <th>
                      <span>Exceptions</span>
                    </th>
                    <th class="text-right">
                      <span>Change</span>
                    </th>
                    <th class="text-right">
                      <span>Adjustment</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(sc, i) of invoice.exceptionsChanges" :key="i">
                    <td>
                      <span v-text="sc.name"></span>
                    </td>
                    <td class="text-right">
                      <span v-text="sc.change_f"></span>
                    </td>
                    <td class="text-right">
                      <span v-text="sc.adjustment_f"></span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="text-right">
                      <span class="font-weight-bold">Total</span>
                    </td>
                    <td class="text-right">
                      <span
                        class="total"
                        v-text="
                          invoice.text(`total`, `schedules-changes-change`)
                        "
                      ></span>
                    </td>
                    <td class="text-right">
                      <span
                        class="total"
                        v-text="
                          invoice.text(
                            `total`,
                            `schedules-changes-adjustment`
                          )
                        "
                      ></span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </template>
          <template v-else>
            <div></div>
          </template>-->
          <rebated-transactions :transactions="invoice.transactions"
                                v-if="invoice.transactions && invoice.transactions.length > 0"
                                :obfuscateNumbers="invoice.show.numbers"></rebated-transactions>

          <div class="d-flex">
            <div>
              <p class="font-weight-bold">Grand total</p>
            </div>
          </div>

          <table class="table table-sm mb-4">
            <thead>
              <tr>
                <th style="width: 8%; white-space: nowrap">
                  <span>Account</span>
                </th>
                <th>
                  <span>Name</span>
                </th>
                <th></th>
                <th class="text-right">
                  <span>Amount</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(ac, i) of invoice.accounts(`grandtotal`)"
                  :key="i">
                <td style="white-space: nowrap">
                  <span v-text="ac.number"></span>
                </td>
                <td>
                  <span v-text="ac.name"></span>
                </td>
                <td :set="nTotal = invoice.getAccountGrandTotal(ac)">
                  <span v-if="nTotal < 0">
                    <em>(Carryover {{value(nTotal * -1) }})</em>
                  </span>
                </td>
                <td class="text-right">
                  <span v-text="invoice.text(`account-grand-total`, ac)"></span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td class="text-right">
                  <span class="font-weight-bold">Total</span>
                </td>
                <td class="text-right"
                    style="width: 65px">
                  <span class="total"
                        v-text="invoice.text(`total`, `account-grand-total`)"></span>
                </td>
              </tr>
            </tfoot>
          </table>
        </scroll-area>
      </template>
      <template v-else>
        <loading></loading>
      </template>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <remove-button :button="invoice.remove"></remove-button>
        <!-- <button
          type="button"
          class="btn btn-sm btn-primary ml-2"
          :disabled="invoice.disabled(`export`)"
          v-on:click="invoice.click(`export`)"
        >
          <span class="button">Export</span>
        </button>-->
      </div>
    </div>
  </div>
</div>
